<template>
  <b-card
    class="mobile-card"
    header-bg-variant="light"
    v-b-toggle="`moisson_${id}`"
  >
    <template #header>
      <AnomalieIcon
        :anomaliesParcelle="item.parcelles[0].anomalies"
      ></AnomalieIcon>
      {{ libelleProduit }}
      <MelangeIcon v-if="melangeEspeces"></MelangeIcon>
    </template>
    <b-container>
      <b-row align-v="center">
        <b-col
          cols="auto"
          class="bg-light bg-left font-up"
          style="width: 80px; border-radius: 0 !important"
        >
          <div v-if="item.statut === statuts.A_MOISSONNER" class="w-100">-</div>
          <div v-else class="w-100">
            <MontantComponent
              :value="item.quantiteMoissonnee"
            ></MontantComponent>
            t
          </div>
        </b-col>
        <b-col class="pr-0">
          <b-row class="m-0">
            <b-col class="font-down pl-0 py-0">
              <div>
                <BadgeComponent
                  :text="item.statut.libelle"
                  :variant="item.statut.variant"
                ></BadgeComponent>
              </div>
              <div v-if="!melangeEspeces">
                {{ libelleVariete }}
              </div>
              <div>
                Pr&eacute;visionnel :
                <MontantComponent
                  :value="item.quantitePrevisionnelle"
                ></MontantComponent>
                t
              </div>
              <b-collapse
                :id="`moisson_${id}`"
                class="label text-center"
                visible
              >
                <b-icon-chevron-down></b-icon-chevron-down>
                Voir les parcelles
              </b-collapse>
            </b-col>
            <b-col cols="auto" class="p-0" @click.stop>
              <MoissonActionsComponent
                :item="item"
                @view="$emit('view')"
                @edit="$emit('edit')"
                @abandon="$emit('abandon')"
                @delete="$emit('delete')"
              ></MoissonActionsComponent>
            </b-col>
          </b-row>
          <b-collapse
            :id="`moisson_${id}`"
            style="border-top: 1px solid lightgrey"
          >
            <b-row
              class="m-0"
              v-for="parcelle in item.parcelles"
              :key="parcelle.id"
              align-v="center"
            >
              <b-col class="p-0 pt-1 font-down">
                <div class="rounded bg-light">
                  {{ parcelle.libelleParcelle }}
                </div>
                <div v-if="!parcelle.culture.isMelangeEspeces" class="pl-2">
                  {{ parcelle.culture.libelleVariete }}
                </div>
                <div class="pl-2">
                  Pr&eacute;visionnel :
                  <MontantComponent
                    :value="parcelle.quantitePrevisionnelle"
                  ></MontantComponent>
                  t
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import AnomalieIcon from "../controls/AnomalieIcon.vue";
import BadgeComponent from "../BadgeComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import MoissonActionsComponent from "../controls/MoissonActionsComponent.vue";
export default {
  name: "MoissonCard",
  components: {
    AnomalieIcon,
    BadgeComponent,
    MontantComponent,
    MoissonActionsComponent,
  },
  props: ["item"],
  data() {
    return {
      id: uuidv4(),
      statuts: this.$store.state.decl.statutsMoisson,
    };
  },
  computed: {
    melangeEspeces() {
      // Toutes les parcelles partagent le(s) même(s) produit(s)
      return this.item.parcelles[0].culture.isMelangeEspeces;
    },
    melangeVarietes() {
      let libelleVariete = this.item.parcelles[0].culture.libelleVariete;
      for (let i = 0; i < this.item.parcelles.length; i++) {
        // Soit on a au moins 1 parcelle semée en mélange de variétés
        if (this.item.parcelles[i].culture.isMelangeVarietes) {
          return true;
        }

        // Soit on a mélangé des parcelles avec des variétés pures différentes
        if (this.item.parcelles[i].culture.libelleVariete !== libelleVariete) {
          return true;
        }
      }
      return false;
    },
    libelleProduit() {
      return this.item.parcelles[0].culture.libelleProduit;
    },
    libelleVariete() {
      return this.melangeVarietes
        ? "Mélange de variétés"
        : this.item.parcelles[0].culture.libelleVariete;
    },
  },
};
</script>
