<template>
  <section>
    <TitleBar tabsParent="recolte">
      Campagne {{ $route.params.codeRecolte }}
      <template #toolbar-content>
        <b-button-group>
          <b-button
            pill
            variant="primary"
            @click="openMoissonEditor(null, 'EDITION')"
          >
            <b-icon-check-all></b-icon-check-all>
            Moissonner
          </b-button>
        </b-button-group>
        &nbsp;
        <HelpMoissonComponent></HelpMoissonComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
        &nbsp;
        <ExportButtonGroup
          @imprimer="() => {}"
          @exportExcel="() => {}"
          @exportCsv="() => {}"
        ></ExportButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
        @keydown.native.esc="load"
        @keydown.native.enter="validate"
      >
        <template #empty>
          <p>
            <strong>
              Vous n'avez pour le moment aucune parcelle &agrave; moissonner.
            </strong>
          </p>
          <p v-if="$store.state.decl.parcelles.length <= 0">
            Cr&eacute;ez vos parcelles dans
            <b-link :to="{ name: 'parcelles', params: $route.params }">
              l'onglet Parcelles</b-link
            >
            puis saisissez leur culture dans
            <b-link :to="{ name: 'emblavements', params: $route.params }">
              l'onglet Emblavements</b-link
            >.
          </p>
          <p v-else>
            Semez vos parcelles dans
            <b-link :to="{ name: 'emblavements', params: $route.params }">
              l'onglet Emblavements</b-link
            >
            pour les voir apparaitre dans cet &eacute;cran.
          </p>
        </template>
        <template #mobile-card="{ item }">
          <MoissonCard
            :item="item"
            @view="openMoissonEditor(item, 'VISUALISATION')"
            @edit="openMoissonEditor(item, 'EDITION')"
            @abandon="openMoissonEditor(item, 'ABANDON')"
            @delete="deleteMoisson(item.id)"
          ></MoissonCard>
        </template>
        <template #cell(parcelle)="{ item }">
          <section v-for="p in item.parcelles" :key="p.id">
            {{ p.libelleParcelle }}
          </section>
        </template>
        <template #cell(libelleProduit)="{ item }">
          <section v-for="p in item.parcelles" :key="p.id">
            {{ p.culture.libelleProduit }}
            <MelangeIcon v-if="p.culture.isMelangeEspeces">
              M&eacute;lange d'esp&egrave;ces
            </MelangeIcon>
          </section>
        </template>
        <template #cell(libelleVariete)="{ item }">
          <section v-for="p in item.parcelles" :key="p.id">
            {{ p.culture.libelleVariete }}
            <MelangeIcon v-if="p.culture.isMelangeVarietes">
              M&eacute;lange de vari&eacute;t&eacute;s
            </MelangeIcon>
          </section>
        </template>
        <template #cell(quantitePrevisionnelle)="{ item }">
          <section v-for="p in item.parcelles" :key="p.id">
            <MontantComponent
              :value="p.quantitePrevisionnelle"
            ></MontantComponent>
          </section>
        </template>
        <template #cell(statut)="{ item, index }">
          <SelectStatutMoissonComponent
            :ref="`selectStatut_${index}`"
            label=""
            labelCols="auto"
            :readOnly="editing !== index"
            rules="required"
            v-model="item.statut"
          >
            <AnomalieIcon
              :anomaliesParcelle="item.parcelles[0].anomalies"
            ></AnomalieIcon>
            <BadgeComponent
              :text="item.statut.libelle"
              :variant="item.statut.variant"
              @click="quickEdit(index)"
            ></BadgeComponent>
          </SelectStatutMoissonComponent>
        </template>
        <template #cell(quantiteMoissonnee)="{ item, index }">
          <CustomInputNumberComponent
            v-if="
              (item.id || editing === index) &&
              item.statut === statuts.MOISSONNEE
            "
            :ref="`inputQuantite_${index}`"
            label=""
            :readOnly="editing !== index"
            :quickEdit="true"
            rules="required|min_value:0.1"
            v-model="item.quantiteMoissonnee"
            @edit="quickEdit(index)"
          ></CustomInputNumberComponent>
        </template>
        <template #cell(actions)="{ item, index }">
          <MoissonActionsComponent
            :item="item"
            :editing="editing === index"
            :commentValue="item.commentaire"
            :commentDisabled="!item.id"
            @cancel="load"
            @validate="validate"
            @comment="openCommentaireEditor(item)"
            @view="openMoissonEditor(item, 'VISUALISATION')"
            @edit="openMoissonEditor(item, 'EDITION')"
            @abandon="openMoissonEditor(item, 'ABANDON')"
            @delete="deleteMoisson(item.id)"
          ></MoissonActionsComponent>
        </template>
        <template #pagination>
          <b-row class="mx-3 mx-xl-5">
            <b-col class="pl-1">
              <b-progress class="mt-1" :max="progressMax" show-value>
                <b-progress-bar
                  :value="progressEnAttente"
                  precision="2"
                  variant="warning"
                ></b-progress-bar>
                <b-progress-bar
                  :value="progressMoissonne"
                  precision="2"
                  variant="success"
                ></b-progress-bar>
                <b-progress-bar
                  :value="progressAbandonne"
                  precision="2"
                  variant="danger"
                ></b-progress-bar>
              </b-progress>
            </b-col>
            <b-col cols="auto" class="pr-0">
              sur
              <strong>
                <MontantComponent
                  :value="surfaceExploitation"
                ></MontantComponent>
                ha
              </strong>
            </b-col>
          </b-row>
        </template>
      </PaginatedTable>
      <MoissonEditionDialog ref="moissonEditionDialog"></MoissonEditionDialog>
      <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
      <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpMoissonComponent from "../../components/controls/HelpMoissonComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ExportButtonGroup from "../../components/controls/ExportButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import MoissonCard from "../../components/cards/MoissonCard.vue";
import SelectStatutMoissonComponent from "../../components/inputs/SelectStatutMoissonComponent.vue";
import CustomInputNumberComponent from "../../components/inputs/CustomInputNumberComponent.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import BadgeComponent from "../../components/BadgeComponent.vue";
import AnomalieIcon from "../../components/controls/AnomalieIcon.vue";
import MoissonActionsComponent from "../../components/controls/MoissonActionsComponent.vue";
import MoissonEditionDialog from "../../components/dialogs/MoissonEditionDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "MoissonView",
  components: {
    TitleBar,
    HelpMoissonComponent,
    TableButtonGroup,
    ExportButtonGroup,
    ErrorComponent,
    PaginatedTable,
    MoissonCard,
    SelectStatutMoissonComponent,
    CustomInputNumberComponent,
    MontantComponent,
    BadgeComponent,
    AnomalieIcon,
    MoissonActionsComponent,
    MoissonEditionDialog,
    CommentaireDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.MOISSON),
      error: null,
      page: null,
      editing: null,
      statuts: this.$store.state.decl.statutsMoisson,
    };
  },
  computed: {
    ...tableComputed,
    progressionMoisson() {
      return this.$store.getters["decl/progressionMoisson"];
    },
    progressMax() {
      return this.progressionMoisson.total;
    },
    progressEnAttente() {
      return this.progressionMoisson.enAttente;
    },
    progressMoissonne() {
      return this.progressionMoisson.moissonne;
    },
    progressAbandonne() {
      return this.progressionMoisson.abandonne;
    },
    surfaceExploitation() {
      return this.$store.getters["decl/surfaceExploitation"];
    },
    anomaliesParcelle() {
      return this.$store.state.decl.anomaliesParcelle;
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    load() {
      try {
        this.busy = true;
        this.editing = null;

        // Récupérer les données dans le store
        let parcelles = UtilsService.deepCopy(
          this.$store.state.decl.parcelles.filter(
            (p) => !!p.idCulture && p.etatParcelle !== "INTENTION"
          )
        ).map((p) => {
          p.ilot = this.$store.getters["decl/ilot"](p.idIlot);
          p.libelleParcelle = `${p.ilot.libelle} - ${p.libelle}`;
          p.culture = this.$store.getters["decl/culture"](p.idCulture);
          p.emblavements = this.$store.getters["decl/emblavementsFromParcelle"](
            p.id
          );
          p.quantitePrevisionnelle =
            (p.surfaceEmblavee - p.surfaceRetiree) *
            p.emblavements.reduce(
              (acc, next) => acc + next.rendementAgriculteur,
              0.0
            );
          return p;
        });

        // Ajouter d'abord les moissons existantes
        // Puis les parcelles non moissonnées
        let moissons = UtilsService.deepCopy(
          this.$store.state.decl.moissons
        ).map((m) => {
          m.parcelles = parcelles.filter((p) => p.idMoisson === m.id);
          return m;
        });
        moissons.push(
          ...parcelles
            .filter((p) => !p.idMoisson)
            .map((p) => ({
              id: undefined,
              quantiteMoissonnee: 0.0,
              commentaire: null,
              parcelles: [p],
            }))
        );

        // Enrichir les données
        moissons = moissons.map((m) => {
          m.culture = this.$store.getters["decl/culture"](
            m.parcelles[0].idCulture
          );
          m.quantitePrevisionnelle = m.parcelles.reduce(
            (acc, next) => acc + next.quantitePrevisionnelle,
            0.0
          );

          // Détermination du statut de la ligne
          if (!m.id) {
            m.statut = this.statuts.A_MOISSONNER;
          } else if (m.quantiteMoissonnee > 0.0) {
            m.statut = this.statuts.MOISSONNEE;
          } else {
            m.statut = this.statuts.ABANDONNEE;
          }
          return m;
        });

        // Filtrer la liste
        moissons = moissons.filter(this.getFilterFunction());

        // Trier et paginer le résultat
        this.page = UtilsService.readOnlyPage(
          moissons,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          this.getSortFunction()
        );
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async quickEdit(index) {
      this.editing = index;
      if (!index) {
        return;
      }
      await this.$nextTick();
      this.$refs[`inputQuantite_${index}`]?.focus();
      this.$refs[`selectStatut_${index}`]?.focus();
    },
    async validate() {
      let target = this.page.content[this.editing];
      if (!target) {
        return;
      }

      // Valider le formulaire
      await this.$refs[`selectStatut_${this.editing}`]?.validate();
      await this.$refs[`inputQuantite_${this.editing}`]?.validate();

      // Si statut "A moissonner", supprimer la moisson
      if (target.statut === this.statuts.A_MOISSONNER) {
        if (target.id) {
          this.deleteMoisson(target.id);
        } else {
          this.load();
        }
        return;
      }

      // Si statut "Moissonnee" ou "Abandonnée", mettre à jour la moisson
      if (target.statut === this.statuts.ABANDONNEE) {
        target.quantiteMoissonnee = 0.0;
      }
      this.moissonChanged(target);
    },
    async openCommentaireEditor(moisson) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Parcelle(s)",
        items: moisson.parcelles.map((p) => `${p.ilot.libelle} - ${p.libelle}`),
        labelCommentaire: "Commentaire moisson",
        commentaire: moisson.commentaire,
      });
      if (resultat != null) {
        moisson.commentaire = resultat;
        this.moissonChanged(moisson);
      }
    },
    async openMoissonEditor(moisson, mode) {
      let result = await this.$refs.moissonEditionDialog.show({
        moisson: moisson ?? {
          id: undefined,
          quantiteMoissonnee: 0.0,
          commentaire: null,
          parcelles: [],
        },
        mode,
      });
      if (result) {
        this.moissonChanged(result);
      }
    },
    async moissonChanged(moisson) {
      let copy = {
        ...moisson,
        parcelles: moisson.parcelles.map((p) => p.id),
      };
      try {
        await this.$store.dispatch("decl/createUpdateMoisson", copy);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
    async deleteMoisson(idMoisson) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Effacer cette moisson ?",
        messages: [
          `Etes-vous sûr.e de vouloir effacer les informations de cette moisson ?`,
          `Les données saisies seront définitivement perdues.`,
        ],
      });
      if (!confirmation) {
        return;
      }
      try {
        await this.$store.dispatch("decl/deleteMoisson", idMoisson);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
  },
};
</script>
