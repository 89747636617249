<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Cet &eacute;cran remplace l'ancienne enqu&ecirc;te de stockage d'Isagri,
        difficilement exploitable par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Vous devez y pr&eacute;ciser le
        <strong>
          poids brut total moissonn&eacute;, sans d&eacute;duire votre
          autoconsommation</strong
        >.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Vous n'&ecirc;tes pas oblig&eacute; de tout saisir. L'application vous
        indique les saisies n&eacute;cessaires pour votre coop&eacute;rative
        avec l'icone
        <b-icon-exclamation-triangle-fill
          variant="danger"
        ></b-icon-exclamation-triangle-fill
        >.
      </b-col>
    </b-row>
    <QuestionBloc>Comment calculer mon poids brut stock&eacute; ?</QuestionBloc>
    <b-row class="mb-3">
      <b-col>
        Tout d&eacute;pend de votre stockage ! On vous explique tout dans nos
        <b-link href="/doc/conseils_estimation_volume.pdf" target="_blank">
          <b-icon-file-pdf class="icon-pdf"></b-icon-file-pdf> conseils pour
          estimer vos volumes</b-link
        >.
      </b-col>
    </b-row>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import QuestionBloc from "../blocs/QuestionBloc.vue";
export default {
  name: "HelpMoissonComponent",
  components: { HelpComponent, QuestionBloc },
};
</script>
