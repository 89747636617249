<template>
  <ValidationObserver ref="observer" slim>
    <ValidationProvider
      ref="provider"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group
        :id="`group-${id}`"
        :label="label"
        :label-for="`select-${id}`"
        :label-cols="computedLabelCols['xs']"
        :label-cols-sm="computedLabelCols['sm']"
        :label-cols-md="computedLabelCols['md']"
        :label-cols-lg="computedLabelCols['lg']"
        :label-cols-xl="computedLabelCols['xl']"
        :label-class="computedLabelClass"
        :description="description"
      >
        <b-form-select
          ref="input"
          :id="`select-${id}`"
          :name="`select-${id}`"
          :options="options"
          :disabled="computedDisabled"
          :state="getValidationState(validationContext)"
          :aria-describedby="`feedback-${id}`"
          :value="value"
          @input="onInput"
          @change="(value) => $emit('change', value)"
        ></b-form-select>
        <b-form-invalid-feedback
          :id="`feedback-${id}`"
          :state="getValidationState(validationContext)"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { computed as inputsComputed } from "../../services/inputs.service";
import {
  computed as validationComputed,
  methods as validationMethods,
} from "../../services/validation.service";
export default {
  name: "ValidatedSelectComponent",
  components: { ValidationObserver, ValidationProvider },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // tableau des options { value: ... , text: ... }
    options: {
      type: Array,
      required: true,
    },
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété description du form group
    description: String,
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
  computed: {
    ...inputsComputed,
    ...validationComputed,
  },
  methods: {
    ...validationMethods,
  },
};
</script>
