<template>
  <b-modal ref="modal" scrollable :size="size" @close="cancel">
    <template #modal-title>{{ title }}</template>
    <b-row v-for="(message, index) in messages" :key="index">
      <b-col>
        <p>{{ message }}</p>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              {{ cancelLabel }}
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              {{ validateLabel }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  data: () => ({
    size: null,
    title: null,
    messages: [],
    validateLabel: null,
    cancelLabel: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options) {
      this.size = options?.size;
      this.title = options?.title ?? "Confirmation";
      this.messages = options?.messages ?? ["Confirmez-vous cette action ?"];
      this.validateLabel = options?.validate ?? "Confirmer";
      this.cancelLabel = options?.cancel ?? "Annuler";
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(true);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
  },
};
</script>

<style scoped></style>
