<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Mon bilan de moisson</template>
    <template v-if="moisson">
      <SelectParcellesComponent
        v-if="!moisson.id && mode === 'EDITION'"
        ref="selectParcelles"
        description="Seules les parcelles de même culture peuvent être groupées."
        :accept="parcellesFilter"
        :value="moisson.parcelles"
        @input="parcellesChanged"
      ></SelectParcellesComponent>
      <b-container v-else class="context-container">
        <GenericReadonlyComponent
          v-if="moisson.parcelles.length === 1"
          label="Parcelle :"
          labelCols="auto"
        >
          {{ moisson.parcelles[0].libelleParcelle }} (<MontantComponent
            :value="
              moisson.parcelles[0].surfaceEmblavee -
              moisson.parcelles[0].surfaceRetiree
            "
          ></MontantComponent>
          ha)
        </GenericReadonlyComponent>
        <GenericReadonlyComponent v-else label="Parcelles :">
          <ul>
            <li v-for="parcelle in moisson.parcelles" :key="parcelle.id">
              {{ parcelle.libelleParcelle }} (<MontantComponent
                :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
              ></MontantComponent>
              ha)
            </li>
          </ul>
        </GenericReadonlyComponent>
      </b-container>
      <p>
        Vous aviez pr&eacute;vu
        <strong>
          <MontantComponent :value="quantitePrevisionnelle"></MontantComponent>
          t
        </strong>
        pour ces parcelles, soit un rendement moyen de
        <strong>
          <MontantComponent :value="rendementPrevisionnel"></MontantComponent>
          t/ha</strong
        >.
      </p>
      <CustomInputNumberComponent
        ref="inputQuantite"
        label="Vous avez réellement moissonné :"
        :labelCols="{ md: 7 }"
        append="t"
        :readOnly="mode !== 'EDITION'"
        rules="required|min_value:0"
        v-model="moisson.quantiteMoissonnee"
        @input="quantiteChanged"
      ></CustomInputNumberComponent>
      <CustomInputNumberComponent
        ref="inputRendement"
        label="Soit un rendement moyen de :"
        :labelCols="{ md: 7 }"
        append="t/ha"
        :readOnly="mode !== 'EDITION'"
        rules="required|min_value:0"
        v-model="moisson.rendementMoisson"
        @input="rendementChanged"
      ></CustomInputNumberComponent>
      <InputCommentaireComponent
        label="Commentaire moisson :"
        :labelCols="{ md: 7 }"
        :readOnly="mode === 'VISUALISATION'"
        :showIfEmpty="mode === 'ABANDON'"
        v-model="moisson.commentaire"
      >
        <em v-if="mode === 'ABANDON'">
          Utilisez cet espace pour expliquer bri&egrave;vement pourquoi ces
          parcelles ne seront pas moissonn&eacute;es.
        </em>
      </InputCommentaireComponent>
    </template>
    <template #modal-footer>
      <ViewEditDialogButtons
        :readOnly="mode === 'VISUALISATION'"
        @edit="mode = 'EDITION'"
        @cancel="cancel"
        @validate="validate"
      ></ViewEditDialogButtons>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import SelectParcellesComponent from "../inputs/SelectParcellesComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import ViewEditDialogButtons from "../dialogs/ViewEditDialogButtons.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "MoissonEditionDialog",
  components: {
    SelectParcellesComponent,
    MontantComponent,
    GenericReadonlyComponent,
    CustomInputNumberComponent,
    InputCommentaireComponent,
    ViewEditDialogButtons,
  },
  data: () => ({
    id: `moisson-${uuidv4()}`,
    mode: "EDITION",
    moisson: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    parcellesFilter() {
      // Si aucune parcelle n'est encore sélectionnée
      // Ne proposer que les parcelles qui ont une culture et qui sont semées
      if (this.moisson.parcelles.length <= 0) {
        return (p) => !!p.idCulture && p.etatParcelle === "SEMEE";
      }

      // Sinon on accepte de mélanger les variétés (même produit seulement)
      // Fonctionne aussi avec les mélanges d'espèces
      let codeCulture = this.$store.getters["decl/culture"](
        this.moisson.parcelles[0].idCulture
      ).codeCulture.split("#")[0];
      return (p) =>
        !!p.idCulture &&
        p.etatParcelle === "SEMEE" &&
        this.$store.getters["decl/culture"](p.idCulture).codeCulture.split(
          "#"
        )[0] === codeCulture;
    },
    quantitePrevisionnelle() {
      return (
        this.moisson?.parcelles.reduce(
          (acc, next) => acc + (next.quantitePrevisionnelle ?? 0.0),
          0.0
        ) ?? 0.0
      );
    },
    surfaceTotale() {
      return this.moisson?.parcelles.reduce(
        (acc, next) => acc + next.surfaceEmblavee,
        0.0
      );
    },
    rendementPrevisionnel() {
      return this.moisson?.parcelles.length > 0
        ? this.quantitePrevisionnelle / this.surfaceTotale
        : 0.0;
    },
  },
  methods: {
    async parcellesChanged(parcelles) {
      // Mettre à jour l'objet moisson avec les parcelles enrichies
      this.moisson.parcelles = parcelles.map((p) => {
        p.ilot = this.$store.getters["decl/ilot"](p.idIlot);
        p.libelleParcelle = `${p.ilot.libelle} - ${p.libelle}`;
        p.culture = this.$store.getters["decl/culture"](p.idCulture);
        p.emblavements = this.$store.getters["decl/emblavementsFromParcelle"](
          p.id
        );
        p.quantitePrevisionnelle =
          (p.surfaceEmblavee - p.surfaceRetiree) *
          p.emblavements.reduce(
            (acc, next) => acc + next.rendementAgriculteur,
            0.0
          );
        return p;
      });

      // Attendre la mise à jour des computed properties
      await this.$nextTick();

      // Réinitialiser quantité et rendement affichés
      this.moisson.quantiteMoissonnee = this.quantitePrevisionnelle;
      this.quantiteChanged();
    },
    quantiteChanged() {
      if (this.moisson.parcelles.length === 0) {
        this.moisson.rendementMoisson = 0.0;
      } else {
        this.moisson.rendementMoisson =
          Math.round(
            ((this.moisson.quantiteMoissonnee ?? 0.0) / this.surfaceTotale) *
              100.0
          ) / 100.0;
      }
    },
    rendementChanged() {
      if (this.moisson.parcelles.length === 0) {
        this.moisson.quantiteMoissonnee = 0.0;
      } else {
        this.moisson.quantiteMoissonnee =
          Math.round(
            (this.moisson.rendementMoisson ?? 0.0) * this.surfaceTotale * 100.0
          ) / 100.0;
      }
    },
    /**
     * mode = VISUALISATION, EDITION, ABANDON
     * moisson = objet de travail (non null)
     */
    show(options) {
      this.mode = options.mode ?? "EDITION";
      this.moisson = UtilsService.deepCopy(options.moisson);

      // Précalculer quantité et rendement à moissonner
      if (!this.moisson.id) {
        this.moisson.quantiteMoissonnee =
          this.mode === "ABANDON"
            ? 0.0
            : this.moisson.quantitePrevisionnelle ?? 0.0;
      }
      this.quantiteChanged();

      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      // Valider le formulaire
      await this.$refs.selectParcelles?.validate();
      await this.$refs.inputQuantite.validate();
      await this.$refs.inputRendement.validate();

      this.$refs["modal"].hide();
      this.resolvePromise(this.moisson);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
