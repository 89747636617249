<template>
  <b-container fluid class="standard-bloc" :style="cssVars">
    <b-row>
      <b-col cols="auto" class="col-icon">
        <b-icon :icon="icon" class="icon" :style="cssVars"></b-icon>
      </b-col>
      <b-col align-self="center" class="content" :style="cssVars">
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "StandardIconBloc",
  props: ["icon", "backColor", "frontColor", "border"],
  computed: {
    cssVars() {
      return {
        "--back-color": this.backColor || "white",
        "--front-color": this.frontColor || "black",
        "--border": this.border || "0",
      };
    },
  },
};
</script>

<style scoped>
.standard-bloc {
  background: var(--back-color);
  border: var(--border);
  border-radius: 10px;
  margin-bottom: 1em;
  padding: 0.5em;
  overflow: hidden;
}
.standard-bloc .col-icon {
  height: 30px;
  width: 30px;
  margin: 10px 20px;
  padding: 0;
}
.standard-bloc .icon {
  height: 30px;
  width: 30px;
  fill: var(--front-color);
}
.standard-bloc .content {
  margin: 5px 1.5em 0 0;
  padding: 0;
  color: var(--front-color);
}
</style>