<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-for="`select-${id}`"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :description="description"
  >
    <b-form-select
      ref="input"
      :id="`select-${id}`"
      :name="`select-${id}`"
      :options="options"
      :disabled="computedDisabled"
      :value="value"
      @input="(value) => $emit('input', value)"
      @change="(value) => $emit('change', value)"
    ></b-form-select>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { computed as inputsComputed } from "../../services/inputs.service";
export default {
  name: "UnvalidatedSelectComponent",
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // tableau des options { value: ... , text: ... }
    options: {
      type: Array,
      required: true,
    },
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété description du form group
    description: String,
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
  computed: {
    ...inputsComputed,
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
