<template>
  <b-badge pill :variant="variant" :href="computedHref" @click="click()">
    <b-icon v-if="icon && placement !== 'right'" :icon="icon"></b-icon>
    {{ text }}
    <b-icon v-if="icon && placement === 'right'" :icon="icon"></b-icon>
  </b-badge>
</template>

<script>
export default {
  name: "BadgeComponent",
  /**
   * text = texte du badge
   * icon = nom de l'icone à ajouter au badge
   * placement = 'right' pour afficher l'icone à droite (à gauche par défaut)
   * size = taille CSS du texte (1.1em par défaut)
   * clickable = false ou 'false' pour désactiver le clic (true par défaut)
   * variant = variant Bootstrap à appliquer au badge
   */
  props: ["text", "icon", "placement", "size", "clickable", "variant"],
  computed: {
    computedHref() {
      return this.clickable === false || this.clickable === "false"
        ? undefined
        : "#";
    },
  },
  methods: {
    click() {
      if (this.clickable !== false && this.clickable !== "false") {
        this.$emit("click");
      }
    },
  },
};
</script>
