<template>
  <span v-if="anomalie" :class="computedClasses">
    <b-icon-exclamation-triangle-fill :id="`anomalies-${id}`" variant="danger">
    </b-icon-exclamation-triangle-fill>
    <b-tooltip :target="`anomalies-${id}`" triggers="hover" variant="primary">
      <section v-if="emblavementNonSaisi">Emblavement manquant</section>
      <section v-if="parcelleNonSemee">Parcelle &agrave; semer</section>
      <section v-if="parcelleNonMoissonnee">
        Parcelle &agrave; moissonner
      </section>
      <section v-if="autoconsoNonSaisie">Autoconsommation manquante</section>
      <section v-if="quantiteAutoconsoInvalide">
        Quantit&eacute; autoconsomm&eacute;e sup&eacute;rieure &agrave; la
        quantit&eacute; disponible
      </section>
      <section v-if="enlevementNonSaisi">Enl&egrave;vement manquant</section>
      <section v-if="modeEnlevementInvalide">
        Mode d'enl&egrave;vement invalide
      </section>
      <section v-if="dateEnlevementInvalide">
        Date d'enl&egrave;vement invalide
      </section>
      <section v-if="computedAnomaliesGroupement">
        Anomalie sur l'une des parcelles
      </section>
    </b-tooltip>
  </span>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "AnomalieIcon",
  /**
   * classes = classes CSS additionnelles à appliquer sur l'élément parent
   * anomaliesParcelle = tableau des codes anomalies à afficher
   * anomaliesCulture = tableau des codes anomalies à afficher
   * anomaliesAutoconso = tableau des codes anomalies à afficher
   * anomaliesGroupement = true pour indiquer des anomalies dans le groupement
   * forceDisplay = force l'affichage de l'anomalie même si on n'est pas sur le bon écran
   */
  props: [
    "classes",
    "anomaliesParcelle",
    "anomaliesCulture",
    "anomaliesAutoconso",
    "anomaliesEnlevement",
    "anomaliesGroupement",
    "forceDisplay",
  ],
  data: () => ({
    id: uuidv4(),
  }),
  computed: {
    computedClasses() {
      return `d-inline-block mx-1 ${this.classes ?? ""}`;
    },
    computedForceDisplay() {
      return this.forceDisplay === true || this.forceDisplay === "true";
    },
    ecranParcelle() {
      return this.computedForceDisplay || this.$route.name === "parcelles";
    },
    ecranEmblavement() {
      return this.computedForceDisplay || this.$route.name === "emblavements";
    },
    ecranMoisson() {
      return this.computedForceDisplay || this.$route.name === "moisson";
    },
    ecranAutoconso() {
      return (
        this.computedForceDisplay || this.$route.name === "autoconsommation"
      );
    },
    ecranEnlevements() {
      return this.computedForceDisplay || this.$route.name === "enlevements";
    },
    emblavementNonSaisi() {
      return (
        this.ecranEmblavement &&
        !!this.anomaliesParcelle?.includes(
          this.$store.state.decl.anomaliesParcelle.EMBLAVEMENT_NON_SAISI
        )
      );
    },
    parcelleNonSemee() {
      return (
        this.ecranEmblavement &&
        !!this.anomaliesParcelle?.includes(
          this.$store.state.decl.anomaliesParcelle.PARCELLE_NON_SEMEE
        )
      );
    },
    parcelleNonMoissonnee() {
      return (
        this.ecranMoisson &&
        !!this.anomaliesParcelle?.includes(
          this.$store.state.decl.anomaliesParcelle.PARCELLE_NON_MOISSONNEE
        )
      );
    },
    autoconsoNonSaisie() {
      return (
        this.ecranAutoconso &&
        !!this.anomaliesCulture?.includes(
          this.$store.state.decl.anomaliesCulture.AUTOCONSO_NON_SAISIE
        )
      );
    },
    quantiteAutoconsoInvalide() {
      return (
        this.ecranAutoconso &&
        !!this.anomaliesAutoconso?.includes(
          this.$store.state.decl.anomaliesAutoconso.QUANTITE_INVALIDE
        )
      );
    },
    enlevementNonSaisi() {
      return (
        this.ecranEnlevements &&
        !!this.anomaliesCulture?.includes(
          this.$store.state.decl.anomaliesCulture.ENLEVEMENT_NON_SAISI
        )
      );
    },
    modeEnlevementInvalide() {
      return (
        this.ecranEnlevements &&
        !!this.anomaliesEnlevement?.includes(
          this.$store.state.decl.anomaliesEnlevement.MODE_INVALIDE
        )
      );
    },
    dateEnlevementInvalide() {
      return (
        this.ecranEnlevements &&
        !!this.anomaliesEnlevement?.includes(
          this.$store.state.decl.anomaliesEnlevement.DATE_INVALIDE
        )
      );
    },
    computedAnomaliesGroupement() {
      return (
        this.anomaliesGroupement === true || this.anomaliesGroupement === "true"
      );
    },
    anomalie() {
      return (
        this.emblavementNonSaisi ||
        this.parcelleNonSemee ||
        this.parcelleNonMoissonnee ||
        this.autoconsoNonSaisie ||
        this.quantiteAutoconsoInvalide ||
        this.enlevementNonSaisi ||
        this.modeEnlevementInvalide ||
        this.dateEnlevementInvalide ||
        this.computedAnomaliesGroupement
      );
    },
  },
};
</script>
