<template>
  <GenericReadonlyComponent
    v-if="computedReadOnly"
    :label="label"
    :labelCols="labelCols"
  >
    <slot>{{ readOnlyValue }}</slot>
  </GenericReadonlyComponent>
  <UnvalidatedSelectComponent
    v-else-if="rules == null"
    ref="unvalidatedSelect"
    :options="options"
    :label="label"
    :labelCols="labelCols"
    :disabled="disabled"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></UnvalidatedSelectComponent>
  <ValidatedSelectComponent
    v-else
    ref="validatedSelect"
    :options="options"
    :label="label"
    :labelCols="labelCols"
    :disabled="disabled"
    :rules="rules"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></ValidatedSelectComponent>
</template>

<script>
import GenericReadonlyComponent from "./GenericReadonlyComponent.vue";
import UnvalidatedSelectComponent from "./UnvalidatedSelectComponent.vue";
import ValidatedSelectComponent from "./ValidatedSelectComponent.vue";
export default {
  name: "SelectStatutMoissonComponent",
  components: {
    GenericReadonlyComponent,
    UnvalidatedSelectComponent,
    ValidatedSelectComponent,
  },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // propriété label du form-group
    label: {
      type: String,
      default: "Statut",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  data: () => ({
    options: [],
  }),
  computed: {
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
    readOnlyValue() {
      return this.value?.libelle;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.options = Object.values(this.$store.state.decl.statutsMoisson).map(
        (s) => ({
          value: s,
          text: s.libelle,
        })
      );
    },
    focus() {
      this.$refs.unvalidatedSelect?.focus();
      this.$refs.validatedSelect?.focus();
    },
    async validate() {
      await this.$refs.validatedSelect?.validate();
    },
  },
};
</script>
