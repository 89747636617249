<template>
  <TableActionsComponent
    :editing="editing"
    :commentValue="commentValue"
    :commentDisabled="commentDisabled"
    @cancel="$emit('cancel')"
    @validate="$emit('validate')"
    @comment="$emit('comment')"
    @view="$emit('view')"
    @edit="$emit('edit')"
  >
    <b-dropdown-item
      class="d-block d-md-none"
      :disabled="!item.id"
      @click="$emit('view')"
    >
      <b-icon-eye></b-icon-eye>
      Visualiser la moisson
    </b-dropdown-item>
    <b-dropdown-item class="d-block d-md-none" @click="$emit('edit')">
      <b-icon-pencil-square></b-icon-pencil-square>
      {{ item.id ? "Modifier la moisson" : "Moissonner" }}
    </b-dropdown-item>
    <b-dropdown-item :disabled="!!item.id" @click="$emit('abandon')">
      <b-icon-x-lg></b-icon-x-lg>
      Ne sera pas moissonn&eacute;e
    </b-dropdown-item>
    <b-dropdown-item :disabled="!item.id" @click="$emit('delete')">
      <b-icon-trash></b-icon-trash>
      Annuler cette saisie
    </b-dropdown-item>
  </TableActionsComponent>
</template>

<script>
import TableActionsComponent from "./TableActionsComponent.vue";
export default {
  name: "MoissonActionsComponent",
  components: {
    TableActionsComponent,
  },
  /**
   * item = objet moisson
   * editing = true pour basculer en mode édition (false par défaut)
   * commentValue = valeur du commentaire (s'affiche au survol)
   * commentDisabled = désactive le bouton commentaire (false par défaut)
   */
  props: ["item", "editing", "commentValue", "commentDisabled"],
};
</script>
