<template>
  <b-form-group
    :id="`group-${id}`"
    :label="computedLabel"
    :label-for="`input-${id}`"
    :label-cols="labelCols"
    :description="description"
  >
    <b-form-tags :id="`input-${id}`" v-model="selected" no-outer-focus>
      <ul
        v-if="selectedParcelles.length > 0"
        class="list-inline d-inline-block mb-2"
      >
        <li
          v-for="parcelle in selectedParcelles"
          :key="parcelle.id"
          class="list-inline-item"
        >
          <b-form-tag @remove="unselectParcelle(parcelle)" variant="primary">
            {{ parcelle.ilot.libelle }} -
            {{ parcelle.libelle }} (<MontantComponent
              :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
            ></MontantComponent>
            ha)
          </b-form-tag>
        </li>
      </ul>
      <b-dropdown
        ref="dropdown"
        menu-class="w-100"
        size="sm"
        block
        toggle-class="rounded-pill"
        variant="outline-primary"
      >
        <template #button-content>Choisir parcelle(s)</template>
        <b-dropdown-form @submit.stop.prevent="() => {}">
          <b-form-group
            :id="`group-filtre-${id}`"
            label="Filtre"
            :label-for="`input-filtre-${id}`"
            label-cols="auto"
            class="mb-0"
            label-size="sm"
          >
            <b-form-input
              :id="`input-filtre-${id}`"
              :name="`input-filtre-${id}`"
              type="search"
              size="sm"
              autocomplete="off"
              v-model="filtre"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button
          v-for="parcelle in options"
          :key="parcelle.id"
          @click="selectParcelle(parcelle)"
        >
          {{ parcelle.ilot.libelle }} -
          {{ parcelle.libelle }} (<MontantComponent
            :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
          ></MontantComponent>
          ha)
        </b-dropdown-item-button>
        <b-dropdown-text v-if="noContentMessage">
          {{ noContentMessage }}
        </b-dropdown-text>
      </b-dropdown>
    </b-form-tags>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import MontantComponent from "../MontantComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectParcellesComponent",
  components: { MontantComponent },
  /**
   * value = valeur du select (tableau d'objets parcelle)
   * label = label du select
   * labelCols = propriété label-cols du form-group
   * description = description du form-group
   * accept = fonction de filtrage des parcelles (parcelle) => Boolean
   */
  props: ["value", "label", "labelCols", "description", "accept"],
  data() {
    return {
      id: `parcelle-${uuidv4()}`,
      filtre: "",
      selected: [],
    };
  },
  computed: {
    computedLabel() {
      return this.label ?? "Parcelle(s)";
    },
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() || "";
    },
    options() {
      // Rechercher les parcelles vides pouvant correspondre
      return (
        UtilsService.deepCopy(this.$store.state.decl.parcelles)
          // Filtrer les parcelles déjà sélectionnées
          .filter((p) => !this.selected.includes(p.id))
          // Charger l'ilot de la parcelle et générer son libellé
          .map((p) => {
            p.ilot = this.$store.getters["decl/ilot"](p.idIlot);
            p.libelleParcelle = `${p.ilot.libelle} - ${p.libelle}`;
            return p;
          })
          // Filtrer les parcelles qui ne correspondent pas à la recherche
          .filter((p) =>
            p.libelleParcelle.toLowerCase().includes(this.computedFiltre)
          )
          // Filtrer les parcelles qui ne passent pas le filtre personnalisé
          .filter((p) => !this.accept || this.accept(p))
          // Trier par libellé parcelle
          .sort(UtilsService.sortByStringProperty("libelleParcelle"))
          // Ne garder que les 10 premières options
          .slice(0, 10)
      );
    },
    selectedParcelles() {
      return UtilsService.deepCopy(this.$store.state.decl.parcelles)
        .filter((p) => this.selected.includes(p.id))
        .map((p) => {
          // Charger l'ilot de la parcelle et générer son libellé
          p.ilot = this.$store.getters["decl/ilot"](p.idIlot);
          p.libelleParcelle = `${p.ilot.libelle} - ${p.libelle}`;
          return p;
        });
    },
    noContentMessage() {
      return this.options.length > 0
        ? null
        : "Aucune parcelle ne correspond à vos critères de recherche";
    },
  },
  mounted() {
    this.selected = this.value?.map((p) => p.id);
  },
  methods: {
    async selectParcelle(parcelle) {
      this.selected.push(parcelle.id);
      this.filtre = "";
      await this.$nextTick();
      return this.$emit("input", this.selectedParcelles);
    },
    async unselectParcelle(parcelle) {
      this.selected = this.selected.filter((id) => id !== parcelle.id);
      this.filtre = "";
      await this.$nextTick();
      return this.$emit("input", this.selectedParcelles);
    },
    validate() {
      if (!this.value?.length) {
        this.focus();
        throw new Error("Invalid field");
      }
    },
    focus() {
      // FIXME Un moyen plus élégant de cibler le bouton ?
      this.$refs.dropdown.$el.children[0]?.focus();
    },
  },
};
</script>
