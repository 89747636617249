<template>
  <section class="w-100">
    <b-button
      v-if="readOnly"
      block
      pill
      variant="primary"
      @click="$emit('edit')"
    >
      Modifier
    </b-button>
    <b-container v-else fluid>
      <b-row align-h="between">
        <b-col>
          <b-button
            block
            pill
            variant="outline-primary"
            @click="$emit('cancel')"
          >
            Annuler
          </b-button>
        </b-col>
        <b-col>
          <b-button block pill variant="primary" @click="$emit('validate')">
            Enregistrer
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "ViewEditDialogButtons",
  props: ["readOnly"],
};
</script>
